import request from '@/utils/request'

export function getIndexData() {
  return request({
    url: '/index/index',
    method: 'post'
  })
}
export function getBarData() {
  return request({
    url: '/bar/index',
    method: 'post'
  })
}
export function getBarCate(catid) {
  return request({
    url: '/bar/cate',
    method: 'post',
    data:{catid:catid}
  })
}
export function getSearchGoods(k) {
  return request({
    url: '/bar/querygoods',
    method: 'post',
    data:{k:k}
  })
}
export function getContact(id) {
  return request({
    url: '/query/contact',
    method: 'post',
    data:{id:id}
  })
}
export function getAbout(id) {
  return request({
    url: '/query/about',
    method: 'post',
    data:{id:id}
  })
}